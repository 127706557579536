import React from 'react';
import './index.css';



function Contact() {
  return (
    <div id="contact">

      <h2>Contact</h2>
      <p><img src="https://farm3.static.flickr.com/2661/3726769801_c3a84379c9_o.jpg"/></p>

    </div>
  );
}

export default Contact;

